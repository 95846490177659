<template>
    <modal class="addMouldModel" :isShow="isShow"  @keyup.esc="hide">
        <div class="header">模板设置 </div>
        <div class="modal-body">
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">模板编码：</span>
                    <div class="from-input"><input type="text" v-model="code" maxlength="24" /></div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">模板名称：</span>
                    <div class="from-input"><input type="text" v-model="name" maxlength="24" /></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected" @click="confirm()"> 确认</button>
        </div>
        
    </modal>
</template>

<script>
// 模板设置
export default {
    name:'addMouldModel',
    props:{
        isShow:Boolean,
        data:{
            type:Object
        }
    },
    data(){
        return {
            //编号
            code:'',
            //名称
            name:''
        }
    },
    watch:{
        isShow:{
            handler(newVal){
                if(newVal){
                    this.code=this.data?.Tmplate_Code||"";
                    this.name=this.data?.Tmplate_Name||"";
                }
            },
            immediate:true,
        },
    },
    mounted(){
    },
    methods:{
        hide(){
            this.$emit("closeModel");
        },
        confirm(){
            let data=Object.assign({},this.data,{
                Tmplate_Code:this.code.trim(),
                Tmplate_Name:this.name.trim()
            })
            this.$emit("confirm",data,data?.TemplateLimitFoods)
        }
    }
}
</script>

<style lang="scss">
@import './addMouldModel.scss'
</style>